<template>
  <div class="w-100 p-2 mt-2">
    <section class="content">
      <DashboardReports></DashboardReports>
    </section>
  </div>
</template>

<script>

import axios from "axios";
import DashboardReports from "@/modules/dashboard/containers/DashboardReports.vue";

export default {
  components: {DashboardReports},
  data() {
    return {

    };
  },
  mounted() {
    axios.defaults.headers.common['X-AUTH-TOKEN'] = this.apiKey;
  },
  computed: {
    apiKey: function () {
      return this.$store.getters["root/apiKey"];
    },
    dashboard: function () {
      return this.$store.getters["dashboardStore/dashboard"];
    }
  }
}
</script>
