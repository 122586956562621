<template>
  <div>
    <p>Dashboard</p>

    <span>
      Actual gold price: {{goldPriceAmount}}$ for gram, date: {{convertDate(goldPriceDate, 'YYYY-MM-DD')}}
    </span>
    <br/><br/>
    <p v-if="isLive != 1">
    <b-button @click="calculate()" size="sm" variant="success">Execute daily calculations</b-button><br/><br/>
    <b-button @click="affiliation()" size="sm" variant="success">Calculate affiliation profits</b-button><br/><br/>
    <b-button @click="withdraw()" size="sm" variant="success">Pool share withdraw</b-button><br/><br/>
    </p>

  </div>

</template>

<script>

import {mapState} from "vuex";
import {convertDate} from "../../../main";
import axios from "axios";
import alertify from "alertifyjs";

export default {
  name: "DashboardReports",
  data() {
    return {
      isLive: process.env.VUE_APP_LIVE
    }
  },
  computed: {
    ...mapState({
      goldPriceAmount: (state) => state.dashboardStore.goldPriceAmount,
      goldPriceDate: (state) => state.dashboardStore.goldPriceDate,
    }),
  },
  methods: {
    convertDate,
    calculate() {
      axios.post('/wallet/force-execute-wallet-time')
          .then(() => {
            alertify.success('Success');
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
      },
    affiliation() {
      axios.post('/wallet/force-calculate-affiliations-profits')
          .then(() => {
            alertify.success('Success');
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    withdraw() {
      axios.post('/wallet/pool-share-withdraw')
          .then(() => {
            alertify.success('Success');
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    }
  },
  mounted() {
    this.$store.dispatch('dashboardStore/loadGoldPrice');
  },
  filters: {

  }
}
</script>
